.sectionHorizontalLinks  {
    .title {
          //  font-size: 6rem;
    //text-transform: uppercase;
    font-weight: $wt-bolder;
    color: $gv-green;
      @include ms-respond(font-size, 3.1);

    }
            .btn {
            color: $gray-dark;
            background: transparent;
            border: none;
            letter-spacing: 0;
            padding: 0;
            font-size: ms(1.8);
            display: block;
            &:last-of-type {
                margin-right: 0;
            }
            
            &:hover,
            &:focus,
            &.focus,
            &:active,
            &.active {
                outline: 0 solid transparent;
                box-shadow: inset 0 0 0 transparent;
            }
            &:hover {
            color: $gray;
            &:after {
                color: $gv-blue;
            }
            }
            &:after {
                font-family: FontAwesome;
                content: "\f054";
                margin-left: 7px;
                  font-size: 1.6rem;

                color: $gv-green;
            }
            @include media('screen', '>=tablet')
            {
            margin-right: 48px;
            display: inline-block;
            }


        }
}