.sectionServices {
    
    .icon-wrapper {
        position: relative;
    }
          .icon-wrapper {
            height: 82px;
           // margin-bottom: 24px;
                    img {
            width: 65px;
            }
        }
    .section-title {
        // font-size: 3em;
    }
    &.c1-bg {
        background-color: $gray-lighter;
    }
    &.c2-bg {
        background-color: $gv-blue;
        .btn {
            background-color: $gv-green;
            color: white;
            &:hover,
            &:active,
            &:focus {
                background-color: lighten($gv-green, 9%);
            }
        }
    }
    &.c3-bg {
        background-color: $gv-green;
        .btn {
            background-color: $gv-blue;
            color: white;
            &:hover,
            &:active,
            &:focus {
                background-color: lighten($gv-blue, 9%);
            }
        }
    }
    &.c4-bg {
        background-color: black;
    }
    &.c5-bg {
        background-color: white;
    }
    &__item {
        margin-bottom: 24px;
        &:last-of-type{
            margin-bottom: 0;

        }
             @include media('screen', '>mid-tablet') {

            margin-bottom: 0;
             }
        
        .inner-wrapper {
            padding: 30px 20px;
            position: relative;
                 .page-icon-above, .page-icon-below, .icon-wrapper {
            height: 82px;
           // margin-bottom: 16px;
      
        }
            &.i-c1-bg {
                background-color: white;
                p {
                    color: $gv-midgray;
                    font-size: ms(1.6);
                }
                .link:hover {
                    color: $gv-green;
                }
            }
            &.i-c2-bg {
                background-color: $gray-lighter;
                .link:hover {
                    color: $gv-green;
                }
            }
            &.b-c1-bg {
                border: 2px solid white;
            }
            &.b-c2-bg {
                border: 2px solid #dddddd;
            }
            &.b-c3-bg {
                border: 2px solid #000000;
            }
            div[class^='page-icon'] i,
            div[class^='page-icon'] img.iconimg,
            div[class^='page-icon'] img.iconimg-hover {
                @include media('screen', '>=mid-tablet') {
                    @include vertical-align();
                }
            }
            .headline-wrapper {
                margin-top: 15px;
                margin-bottom: 15px;
            }
            .title {
                @include ms-respond(font-size, 2.1);

                font-weight: $wt-reg;
                margin: 0;
                margin-bottom: 33px;
                &.small-bold {
                    font-size: ms(2);
                    font-weight: $wt-bolder;
                }
            }
            p {}
            hr {
                border-top: 1px solid lighten($gv-midgray, 30%);
                margin-bottom: 0;
                margin-top: 33px;
            }
        }
    }
}

