.owl-carousel .owl-item {
   @include clearfix();
   padding: 4px 0;
}

.SectionFeatures {
    .features-box-right {
    padding-right: 75px;
    text-align: right;
}
.features-box-left, .features-box-right {
    position: relative;
    display: inline-block;
    margin-bottom: 20px;
    margin-top: 5px;
}
    .features-icon-right {
    padding-left: 20px;
    right: 0;
}
.features-icon-left, .features-icon-right {
    font-size: 50px;
    line-height: 50px;
    display: inline-block;
    position: absolute;
}
}

section.sectionRTE {
    p {
        margin: 0 0 18px;
    }
}

.SectionCounter {
    &__item {
        &:last-of-type {
            margin-bottom: 0;
        }
    }
}