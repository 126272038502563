.gv-rule-heavy {
    width: 220px;
    border-top: 8px solid $gv-green;
}

.gv-rule-lite {
    width: 30%;
    border-top: 6px solid $gv-green; //margin-top: 36px;
    margin-bottom: 52px;
    &.no-mb {
        margin-bottom: 0;
    }
}

section.triangle {
    @include media('screen', '>=desktop') {
        &+section {
            margin-top: -36px;
        }
    }
}

section {
    position: relative;
    &.triangle {
        @include media('screen', '>=desktop') {
            border-color: transparent transparent white transparent;
            &:after {
                content: "";
                display: block;
                width: 0;
                height: 0;
                border-style: solid; // border-top-width: 0;
                // border-right-width: 100vw;
                // border-bottom-width: 150px;
                // border-left-width: 0;
                border-width: 0 0 240px 100vw;
                border-color: transparent transparent white transparent;
                position: absolute;
                z-index: 120;
                bottom: 0;
            }
        }
    }
    &.c1-bg {
        background-color: $gray-lighter;
    }
    &.c2-bg {
        background-color: $gv-blue;
        .btn {
            background-color: $gv-green;
            color: white;
            &:hover,
            &:active,
            &:focus {
                color: white;
                background-color: lighten($gv-green, 9%);
            }
        }
    }
    &.c3-bg {
        background-color: $gv-green;
        .btn {
            background-color: $gv-blue;
            color: white;
            &:hover,
            &:active,
            &:focus {
                color: white;
                background-color: lighten($gv-blue, 9%);
            }
        }
    }
    &.c4-bg {
        background-color: black;
    }
    &.c5-bg {
        background-color: white;
    }
}

.parallax-window {
    min-height: 500px;
    background-color: transparent;
    transition-duration: 0!important;
    transition-delay: 0!important;
    transition-property: all;
    transition-timing-function: linear;
}

hr.SectionRuler {
    border-top-style: solid;
    width: 100%;
    &.c1-hr {
        border-top-color: $gray-lighter;
    }
    &.c2-hr {
        border-top-color: $gv-blue;
    }
    &.c3-hr {
        border-top-color: $gv-green;
    }
    &.white {
        border-top-color: white;
    }
    &.c4-hr {
        border-top-color: black;
    }
    &.th1 {
        border-top-width: 1px;
    }
    &.th2 {
        border-top-width: 2px;
    }
    &.th3 {
        border-top-width: 3px;
    }
    &.th4 {
        border-top-width: 4px;
    }
    &.th5 {
        border-top-width: 5px;
    }
    &.th6 {
        border-top-width: 6px;
    }
    &.th7 {
        border-top-width: 7px;
    }
    &.th8 {
        border-top-width: 8px;
    }
}

i.ion {
    &.c1-icon {
        color: $gray-lighter;
    }
    &.c2-icon {
        color: $gv-blue;
    }
    &.c3-icon {
        color: $gv-green;
    }
    &.c4-icon {
        color: black;
    }
    &.c5-icon {
        color: white;
    }
}

a {
    &:hover,
    &:active,
    &:focus {
        i.ion {
            &.c1-icon {
                color: lighten($gray-lighter, 9%);
            }
            &.c2-icon {
                color: lighten($gv-blue, 9%);
            }
            &.c3-icon {
                color: lighten($gv-green, 9%);
            }
            &.c4-icon {
                color: lighten(black, 9%);
            }
            &.c5-icon {
                color: transparentize(white, 0.9);
            }
        }
    }
}

a {
    &:hover {
        img.iconimg {
            display: none;
            visibility: hidden;
        }
        img.iconimg-hover {
            display: block;
            visibility: visible;
        }
    }
}

.page-icon-above,
.page-icon-below,
.icon-wrapper,
.features-icon-left,
.features-icon-right {
    height: 110px;
    text-align: center;
    img.iconimg,
    img.iconimg-hover {
        //height: 80px;
        width: auto;
        margin: 0 auto;
    }
    img.iconimg {
        display: block;
        visibility: visible;
    }
    img.iconimg-hover {
        display: none;
        visibility: hidden;
    }
}

span.red,
div.red {
    color: red;
}

section {
    position: relative;
    width: 100%;
    background-repeat: no-repeat;
    &.plax {
        background-size: cover;
        background-color: transparent !important;
        @include media('screen', '>mobile') {
            background-attachment: fixed;
        }
        .ie & {
            background-attachment: scroll;
        }
    }
}


.covid-alert {
    padding: 40px;
    background-color: #eef7fb;
    color: #0074B3;
    position: relative;
}

.covid-alert .fa {
    font-size: 40px;
}

.covid-alert a {
    text-decoration: underline;
}

.covid-alert .fa-times {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
}

.covid-alert img {
    margin: 10px auto;
    display: block;
}

.covid-alert .col-sm-1 {
    position: unset;
}


.grecaptcha-badge{
    bottom:84px !important;z-index:1000;
}