body {
    padding-top: 150px;
    @include media('screen', '>=mobile') {
        padding-top: 150px;
    }
    @include media('screen', '>=tablet') {
        padding-top: 105px;
    }
}

.gv-header {
    .container {
        @include media('screen', '>=tablet') {
            height: 105px;
        }
    }
    &__wrapper {
        height: 150px;
        position: fixed;
        @include media('screen', '>=tablet') {
            height: 105px;
        }
        .container {
            @include media('screen', '>tablet') {
                //                    position: relative;
                //       top: 82%;
                //   -webkit-transform: translateY(-50%);
                //   -ms-transform: translateY(-50%);
                //   transform: translateY(-50%);
            }
        }
    }
}

.header .logo a,
.header.sticky .logo a {
    @include media('screen', '<=mobile') {
        padding: 0;
    }
}

.navigation>ul>li a {
    padding: 41px 16px;
    font-size: 1.8rem;
    font-family: $stack-prim;
    font-weight: $wt-reg;
    // letter-spacing: 2px;
    text-decoration: none;
    text-transform: uppercase;
    color: #323232;
    transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    outline: none;
}

header.sticky .navigation>ul {
    li {
        &.nav-has-sub {
            &:hover {
                a {
                    &:after {
                        visibility: visible;
                    }
                }
            }
        }
    }
    >li>span>a,
    >li>a {
        &:after {
            font-family: FontAwesome;
            content: "\f054";
            visibility: hidden;
            margin-left: 7px;
            font-size: 1.2rem;
            position: relative;
            bottom: 0;
            .submenu-opened & {
                bottom: 1px;
            }
            @include media('screen', '>mid-tablet') {
                font-family: FontAwesome;
                content: "\f078";
                visibility: hidden;
                margin-left: 0;
                font-size: 1.6rem;
                position: relative;
                top: 2px;
                display: block;
                text-align: center;
            }
        }
    }
    li.leveltwo {
        border-color: transparent;
        margin-top: 10px;
        &:first-of-type {
            margin-top: 0;
        }
        .submenu-button {
            display: none;
        }
        &:hover {
            @include media('screen', '<mid-tablet') {
                width: 100%;
            }
        }
        @include media('screen', '>mid-tablet') {
            margin-top: 0;
            @include make-sm-column(4);
        }
        a {
            text-transform: uppercase;
            font-weight: $wt-bold;
                // margin-top: 5px;
                // margin-bottom: 5px;

        }
        span.inside-wrapper > a {
            margin-bottom: 5px;
        }
        span.levelthree {
            display: table-cell;
            vertical-align: middle;
            overflow: visible;
            clear: both;
           // height: 26px;
            line-height: 1;
            a {
                text-transform: capitalize;
                font-weight: $wt-reg;
                // margin-top: 5px;
                // margin-bottom: 5px;
        
            }
        }
    }
}

.header .logo {
    float: left;
    height: 34px;
    margin-bottom: 0;
    margin-top: 0;
    width: 22%;
    @include media('screen', '>=tablet') {
        @include vertical-align(absolute);
        margin-top: 0;
    }
    @include media('screen', '>mid-tablet') {
        margin-top: -7px;
    }
    &.mobile {
        float: none;
        clear: both;
        margin-left: auto;
        margin-right: auto;
        width: 56%;
        margin-bottom: 22px;
    }
}

header {
    @include clearfix;
}

.inner-wrapper.open {
    @include media('screen', '>=tablet') {
        top: 105px;
    }
}

span.submenu-button .fa {
    @include media('screen', '>mid-tablet') {
        display: none;
    }
}

.navigation ul {
    @include media('screen', '<768px') {
        top: 150px;
    }
}

.leveltwo,
.levelthree {
    @include media('screen', '>=tablet') {
        //line-height: 1.4;
   
    }
}
p.spacer {
    display: block;
    overflow: visible;
    height: auto;
    line-height: 1;
    float: none;
    clear: both;
}
p.spacer-one {
    display: block;
    overflow: visible;
    height: auto;
    line-height: 1;
    float: none;
    clear: both;
    height: 8px;

}