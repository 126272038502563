.sectionCtaFeature,
.sectionAttachmentFeature {
    &.dark-bg {
        .inner-wrapper {
            .btn-black,
            .btn-white {
                color: white;
                &:hover,
                &:focus,
                &.focus,
                &:active,
                &.active {
                    color: white;
                    background-color: transparent;
                    outline: 0 solid transparent;
                    box-shadow: inset 0 0 0 transparent;
                    &:after {
                        color: lighten($gv-green, 9%);
                    }
                }
            }
        }
    }
    .row {}
    .image-side {
        @include media('screen', '>=desktop') {
            display: table-cell;
            text-align: center;
            vertical-align: middle;
            overflow: auto;
        }
        img {
            margin: 0 auto;
        }
    }
    .right-side {
        margin-top: 24px;
        @include media('screen', '>=desktop') {
            margin-top: 0;
        }
    }
    .inner-wrapper {
        @include media('screen', '>=desktop') {
            display: table;
            vertical-align: middle;
            .wrapper {
                display: table-cell;
                text-align: center;
                vertical-align: middle;
                float: none;
                height: 100%;
            }
        }
        .btn-black,
        .btn-white {
            color: $gray-dark;
            background: transparent;
            border: none;
            letter-spacing: 0;
            padding: 0;
            font-size: ms(2.4);
            &:hover,
            &:focus,
            &.focus,
            &:active,
            &.active {
                outline: 0 solid transparent;
                box-shadow: inset 0 0 0 transparent;
            }
            &:hover {
                color: $gray;
                &:after {
                    color: $gv-blue;
                }
            }
            &:after {
                font-family: FontAwesome;
                content: "\f054";
                margin-left: 7px;
                font-size: 1.8rem;
                position: relative;
                bottom: 0;
                color: $gv-green;
            }
        }
    }
    &.c3-bg {
        .inner-wrapper {
            .btn-black,
            .btn-white {
                &:after {
                    color: $gv-blue;
                }
                &:hover,
                &:focus,
                &.focus,
                &:active {
                    &:after {
                        color: lighten($gv-blue, 15%);
                    }
                }
            }
        }
    }
}