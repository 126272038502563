section.sectionText,
section.SectionTestimonials,
section.sectionCtaFeature,
section.sectionAttachmentFeature,
section.sectionRTE {
       position: relative;
    width: 100%;


    &.plax {
        background-attachment: fixed;
            background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
        .ie & {
            background-attachment: scroll;
        }
    }
    p {
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    p {
        margin: 0;
            font-size: ms(1.8);

        font-weight: $wt-thin;
        margin-top: 0;
    }
    p.lead,
    .lead {
        margin-top: 0;
        font-weight: 300;
    }
    .headline-wrapper {
        margin-top: 0;
        @include media('screen', '>=desktop') {
            margin-top: 0;
        }
        hr {
            margin: 0 auto;
            margin-bottom: #{$vertical-spacer*2}px;
        }
        &.hhl {
            text-align: center;
            @include media('screen', '>=desktop') {
                float: left;
                text-align: right;
                hr {
                    margin-left: 0;
                    margin-right: 0;
                    float: right;
                }
            }
        }
        &.hhr {
            text-align: center;
            @include media('screen', '>=desktop') {
                float: right;
                text-align: left;
                hr {
                    margin-left: 0;
                    margin-right: 0;
                    float: left;
                }
            }
        }
        .title {
            @include ms-respond(font-size, 3.6);
        }
    }
    .quote-author {
        color: $gv-green;
        font-weight: 500;
        //letter-spacing: -.025em;
        font-size: ms(2);
    }
}