.sectionBannerIconsAndWords {
     a {
            &:hover {
        img.iconimg {
            display: none;
            visibility: hidden;
        }
        img.iconimg-hover {
            display: block;
            visibility: visible;
        }
    }
    }
    .inside-wrapper {
                        @include media('screen', '>=desktop') {

        display: table-cell;
        text-align: center;
        vertical-align: middle;
                        }
    }
    .wrap {
        @extend .row;
       
        @include media('screen', '>=desktop') {

        display: table;
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        margin: 0 auto;
        z-index: 1;
                }
    }
    .container {}
    .row {
        font-size: 0;
    }
            @include media('screen', '>=desktop') {

    height: 300px;
            }
    &.taller {
        @include media('screen', '>=desktop') {
            height: 500px;
        }
        .inner-wrapper {
            padding: 50px 0;
        }
    }
    background-repeat: no-repeat;
    background-size: cover;
    &.plax {
        background-attachment: fixed;
        .ie & {
            background-attachment: scroll;
        }
    }
    .inner-wrapper {
        display: block;
        clear: both;
        white-space: nowrap;
        &.i-c1-bg {
            padding: 20px;
            background-color: white;
            color: black;
            .title,
            .ion {
                color: black;
            }
        }
        &.i-c2-bg {
            padding: 20px;
            background-color: $gray-lighter;
            color: black;
            .title,
            .ion {
                color: black;
            }
        }
        &.b-c1-bg {
            border: 2px solid white;
        }
        &.b-c2-bg {
            border: 2px solid #dddddd;
        }
        &.b-c3-bg {
            border: 2px solid #000000;
        }
    }
    &__item {
        margin-bottom: 36px;
        @include media('screen', '>=desktop') {
            margin-bottom: 0;
        }
        .title {
            font-weight: $wt-thin;
            margin: 0;
            white-space: normal;
            @include ms-respond(font-size, 3);
        }
        .icon-wrapper__above {
            margin-bottom: 24px;
            // font-size: 50px;
            position: relative;
        }
        .icon-wrapper__below {
            margin-top: 32px;
            // font-size: 50px;
            position: relative;
        }
        .link {
            // position: relative;
            // display: block;
        }
        div[class^='icon-wrapper'] img,
        div[class^='icon-wrapper'] i {
            margin-left: auto;
            margin-right: auto;
            @include media('screen', '>=desktop') {
                @include vertical-align(relative);
                    max-width: 100px;
            }
        }
                img.iconimg {
            display: block;
            visibility: visible;
        }
    img.iconimg-hover {
        display: none;
        visibility: hidden;

    }
        i.ion {
            font-size: 5rem;
            &.c1-icon {
                color: $gray-lighter;
            }
            &.c2-icon {
                color: $gv-blue;
            }
            &.c3-icon {
                color: $gv-green;
            }
            &.c4-icon {
                color: black;
            }
            &.c5-icon {
                color: white;
            }
        }
    }
    .col-2,
    .col-3,
    .col-4,
    .col-5 {
    
    }
    .col-2 {
        @include col-desk(1, 2, 1em, middle);
    }
    .col-3 {
        @include col-desk(1, 3, 1em, middle);
    }
    .col-4 {
        @include col-desk(1, 4, 1em, middle);
    }
    .col-5 {
        @include col-desk(1, 5, 1em, middle);
    }
}