.home-slider {
    // padding-top: 58px;
    .title {
        font-weight: $wt-bold;
        @include ms-respond(font-size, 5);
        margin-bottom: 30px;
        @include media('screen', '>=tablet') {
            margin-bottom: 60px;
        }
    }
    .fullwidth-slider .item {
        height: 360px;
        position: relative;
        width: 100%;
        @include media('screen', '>=600px') {
            height: 600px;
        }
    }
    p.lead,
    .lead {
        font-size: ms(2, $thread: lead);
        margin-bottom: 30px;
        @include media('screen', '>=tablet') {
            margin-bottom: 60px;
        }
    }
}

//youtube intro
.full-screen-intro, .home-intro {
        .title {
        font-weight: $wt-bold;
        @include ms-respond(font-size, 4.75);
        margin-bottom: 30px;
        @include media('screen', '>=tablet') {
            margin-bottom: 60px;
        }
    }
    &.medium-height {
        height: 600px;

    }
    &.short-height {
        height: 300px;
    }
    &.tall-height {
        height: 700px;
    }
    .btn {
        float: left;
        margin-left: 2%;
       margin-right: 2%;
       margin-bottom: 16px;
       width: 45%;
        @include media('screen', '>=tablet') {
            float: none;
            width: auto;
            margin-right: 16px;
            margin-left: 0;

            &:last-of-type {
                margin-right: 0;
            }

        }        
    }
}