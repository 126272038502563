section.sectionFeatures123 {
    * {
        // outline-color: red;
        // outline-style: solid;
    }
    img.iconimg-hover {
        display: none;
        visibility: hidden;
    }
    a {
        &:hover,
        &.hovered {
            img.iconimg {
                display: none;
                visibility: hidden;
            }
            img.iconimg-hover {
                display: block;
                visibility: visible;
            }
        }
    }
    .features-box-right {
        text-align: center;
        @include media('screen', '>=tablet') {
            text-align: left;
        }
    }
    .features-box-left,
    .features-box-right {
        &.ivt-top {
            @include media('screen', '>=tablet') {
                display: table;
            }
        }
        padding-left: 15px;
        padding-right: 15px;
        @include media('screen', '>=tablet') {
            padding-left: 0;
            padding-right: 0;
        }
        position: relative;
        width: 100%;
        margin-bottom: 42px;
        img {
            max-width: 100%;
        }
        &:first-of-type {
            margin-top: 0;
        }
        &:last-of-type {
            margin-bottom: 0;
            p {
                margin-bottom: 0;
            }
        }
        .ion,
        img,
        img.iconimg {
            display: block;
            visibility: visible;
            margin-left: auto;
            margin-right: auto;
            height: 65px;
            width: auto;
            &.ivt-top {
                display: table-cell;
                vertical-align: middle;
            }
        }
        img.iconimg-hover {
            display: none;
            visibility: hidden;
        }
    }
    .page-icon-above,
    .page-icon-below,
    .icon-wrapper,
    .features-icon-left,
    .features-icon-right {
        height: auto;
    }
    .features-box-left {
        @include media('screen', '>=desktop') {
            float: left;
        }
    }
    .features-box-right {
        @include media('screen', '>=desktop') {
            float: right;
        }
    }
    .features-icon-left {
        text-align: center;
        img,
        .ion {
            @include media('screen', '>=tablet') {
                float: right;
            }
        }
        /* position: relative; */
        /* top: -50%; */
        @include media('screen', '>=tablet') {
            transform: translateY(-50%);
        }
        &.ivt-top {
            @include media('screen', '>=tablet') {
                transform: translateY(0);
                .ion {
                    position: relative;
                    top: -15px;
                }
            }
        }
    }
    .features-icon-right {
        text-align: center;
        img,
        .ion {
            float: left;
        }
        @include media('screen', '>=tablet') {
            transform: translateY(-50%);
        }
        &.ivt-top {
            @include media('screen', '>=tablet') {
                transform: translateY(0);
                .ion {
                    position: relative;
                    top: -15px;
                }
            }
        }
    }
    .features-icon-above {
        text-align: left;
        img,
        img.iconimg {
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 24px;
        }
        &.ivt-top {
            @include media('screen', '>=tablet') {
                transform: translateY(0);
            }
        }
    }
    h5.title {
        @include ms-respond(font-size, 1.6);
        &.StyleA {
            color: $gv-midgray;
            // font-size: 2rem;
            //text-transform: uppercase;
        }
    }
    .features-body {
        margin-top: 18px;
        @include media('screen', '>=tablet') {
            margin-top: 0;
        }
        .features-box-left & {
            padding-right: 10%;
        }
        .features-box-right & {
            padding-left: 10%;
        }
    }
    .body {
        &.StyleA {
            color: $gv-midgray;
        }
        font-size: 1.6rem;
        display: block;
        width: 100%;
        .ivt-centered & {
            @include media('screen', '>=tablet') {
                display: table-cell;
                vertical-align: middle;
            }
        }
    }
    .image-side img {
        max-width: 100%;
    }
    &.vt-centered {
        @include media('screen', '>desktop') {
            .feature-side,
            .image-side {
                display: table;
                vertical-align: middle;
                &-inner {
                    display: table-cell;
                    width: 100%;
                    vertical-align: middle;
                }
            }
            .wrap {
                position: relative;
                height: 100%;
                display: table;
                vertical-align: middle;
            }
        }
    }
}