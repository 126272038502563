/* ----------------------------------------------------------------------*/


/* Blog   --------------------------------------------------------- */


/* ----------------------------------------------------------------------*/


/*.blog-post {    position: relative;}*/

.blog-post {
    display: inline-block;
    width: 100%;
}

.blog-post .post-media {
    position: relative;
    overflow: hidden;
    margin-bottom: 25px;
}

.blog-post .post-media iframe {
    width: 100%;
}

.blog-post .post-meta,
.comment-list .post-meta,
.sidebar-widget .post-meta {
    /* text-transform: uppercase; */
    color: #999;
    // font-size: 13px;
    font-size: 1.3rem;
    /* line-height: 2; */
    margin-bottom: 10px;
}

.blog-post .post-meta span,
.comment-list .post-meta span {
    margin-right: 3px;
}

.blog-post .post-header h5,
.blog-post .post-header h4,
.blog-post .post-header h3 {
    margin-top: 5px;
    margin-bottom: 20px;
}

.blog-post .post-header h2 {
    margin-bottom: 30px;
}

.blog-post .post-entry {
    margin-bottom: 30px;
}

.blog-post .post-entry p,
.blog-post .post-entry blockquote,
.post-entry ul {
    margin-bottom: 20px;
}

.blog-post .post-entry ul {
    // color: #888;
}

.blog-post .post-tag {
    color: #999;
    /* text-transform: uppercase; */
    font-size: 1.4rem;
}

.blog-post .post-tag span {
    margin-right: 10px;
}

.blog-post .post-more-link {
    font-size: 1.3rem;
    font-weight: 600;
    /* text-transform: uppercase; */
    letter-spacing: .05em;
    a {
        color: $gv-blue;
        &:hover {
            color: $gv-green;
            transition: color 0.25s ease 0s;
            -o-transition: color 0.25s ease 0s;
            -moz-transition: color 0.25s ease 0s;
            -webkit-transition: color 0.25s ease 0s;
        }
    }
}

.blog-post .post-more-link a i {
    margin-left: 5px;
    transition: all 0.25s ease 0s;
    -o-transition: all 0.25s ease 0s;
    -moz-transition: all 0.25s ease 0s;
    -webkit-transition: all 0.25s ease 0s;
}

.blog-post .post-more-link a:hover i {
    margin-left: 10px;
}

.blog-post blockquote {
    border-left: none;
    background: #f7f7f7;
    font-size: 17px;
    font-weight: 400;
    padding: 25px 25px;
    margin: 0px;
    color: #888;
    font-weight: 300;
}

.blog-post blockquote:after {
    content: "";
    font-family: FontAwesome;
    padding-left: 7px;
    font-size: 12px;
}

.blog-post blockquote:before {
    content: "";
    font-family: FontAwesome;
    padding-right: 7px;
    font-size: 12px;
}

.blog-post-hr hr {
    margin-bottom: 45px;
}

.post-author {
    display: inline-block;
    position: relative;
    margin-bottom: 40px;
}

.post-author img {
    width: 80px;
}

.post-author .post-author-details {
    margin-left: 20px;
}

.post-author h6 {
    margin-top: 0px;
}

.post-author ul.social {
    margin: 0px;
    padding: 0px;
    font-size: 16px;
}

.post-author ul.social li {
    list-style: none;
    display: inline-block;
    margin: 0 5px;
}

.post-comment {}

.post-comment .comment-numb {
    font-weight: 300;
}

.post-comment .comment-list {
    margin-left: 0px;
    padding-right: 0px;
    list-style: none;
    padding: 0px;
}

.post-comment .comment-list h6 {
    margin-top: 0px;
    margin-bottom: 3px;
}

.post-comment .comment-list li:first-child {
    padding-top: 0px;
}

.post-comment .comment-list li {
    padding-top: 15px;
}

.post-comment .comment-list li:last-child .comment-detail {
    border-bottom: 0px none;
}

.post-comment .comment-list li,
.post-comment .comment-list .comment-reply {
    position: relative;
    padding-left: 65px;
}

.post-comment .comment-list .comment-detail {
    border-bottom: 1px dotted $gray-lighter;
    padding-bottom: 15px;
}

.post-comment .comment-list .comment-reply {
    padding-top: 20px;
}

.post-comment .comment-list .comment-reply-btn i {
    padding-right: 3px;
}

.post-comment .comment-avatar {
    left: 0;
    margin-right: 15px;
    position: absolute;
    width: 50px;
}

.post-comment .comment-avatar img {
    width: 100%;
}

.post-comment-star .star-rat {
    display: inline-block;
}

.post-comment-star label {
    margin-right: 6px;
}


/* Sidebar Menu */

.side-menu {
    background: #323232;
    z-index: 2005;
    color: #fff;
    padding: 30px;
    color: #fff;
    overflow-y: auto;
    position: relative;
}

.side-menu .menu-close {
    left: auto;
    position: absolute;
    right: 30px;
    top: 30px;
    display: inline-block;
    /*background: url("/img/close-white.png") no-repeat center center;*/
    color: #fff;
    font-size: 18px;
    line-height: 0;
}

.side-menu .menu-close:hover {
    /*background: url("/img/close-color.png");*/
    color: #3db54a;
}

.side-menu a {
    color: #fff;
    font-weight: 500;
    font-size: 12px;
}

.side-menu a:hover {
    color: #3db54a;
}

.side-menu ul {
    padding: 0;
}

.side-menu ul li {
    list-style: none;
    margin-bottom: 5px;
}

.side-menu>ul>li>a {
    /* text-transform: uppercase; */
    letter-spacing: 1px;
    display: block;
}

.side-menu input[type="text"],
.side-menu input[type="email"],
.side-menu input[type="tel"],
.side-menu input[type="number"],
.side-menu input[type="password"],
.side-menu input[type="radio"],
.side-menu input[type="checkbox"],
.side-menu textarea,
.side-menu select {
    /*border: 1px solid #1d1d1d;        background: #1d1d1d;        color: #fff;*/
    border: none;
}

.side-menu input[type="text"]:focus,
.side-menu input[type="email"]:focus,
.side-menu input[type="tel"]:focus,
.side-menu input[type="number"]:focus,
.side-menu input[type="radio"]:focus,
.side-menu input[type="checkbox"]:focus,
.side-menu input[type="password"]:focus,
.side-menu textarea:focus,
.side-menu select:focus {
    /*border-color: #3db54a;            color: #fff;*/
}

.side-menu .sign-in {
    padding-bottom: 30px;
    border-bottom: 1px solid #3e3e3e;
    margin-bottom: 30px;
}

.side-menu .sign-in a {
    color: #fff;
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    display: inline-block;
    margin-top: 10px;
}

.side-menu .sign-in a:hover {
    color: #3db54a;
    text-decoration: underline;
}

.side-menu .logo {
    max-width: 120px;
    width: 100%;
}

.side-menu .social {
    list-style: none;
}

.side-menu .social li {
    display: inline-block;
    margin: 0 10px 0 0;
}

.side-menu .social li a {
    font-size: 16px;
}


/* ----------------------------------------------------------------------*/


/* Sidebar Widget   ---------------------------------------------------- */


/* ----------------------------------------------------------------------*/

.sidebar-widget {
    margin-bottom: 50px;
    .title {
        background: $gv-green;
        padding: 12px 12px 6px 12px;
        text-align: center;
        color: white;
        margin-bottom: 0;
        text-transform: uppercase;
        &.recent {
            margin-bottom: 7px;
        }
    }
}

.sidebar-widget h4 {
    margin: 0px;
    padding-top: 0;
    padding-bottom: 15px;
}

.widget-search {
    position: relative;
}

.widget-search input[type="text"] {
    padding-right: 30px;
}

.widget-search input[type="submit"] {
    background-color: rgba(0, 0, 0, 0);
    border: 0 none;
    color: #8d8d8d;
    cursor: pointer;
    display: inline-block;
    float: right;
    font-family: "FontAwesome", sans-serif;
    margin: 0;
    outline: 0 none;
    white-space: nowrap;
    padding: 0 3px;
    text-decoration: none;
    transition: all 0.25s ease 0s;
    -moz-transition: all 0.25s ease 0s;
    -o-transition: all 0.25s ease 0s;
    -webkit-transition: all 0.25s ease 0s;
    position: absolute;
    top: 10px;
    right: 10px;
}

.widget-search input[type="submit"]:hover {
    color: #3db54a;
}

.sidebar-widget hr {
    margin-bottom: 7px;
    border-color: white;
}

.sidebar-widget ul {
    margin: 0px;
    padding: 0px;
}

.sidebar-widget ul li {
    list-style: none;
    font-weight: 400;
    &.category {
        list-style: none;
        font-weight: 400;
        padding: 0;
        text-align: center;
        color: white;
        a {
            padding: 12px 12px 6px 12px;
            display: block;
            background: $gray-lighter;
            &:hover,
            &.active {
                color: $gv-green;
                background: darken($gray-lighter, 9%);
            }
        }
    }
}

.sidebar-widget ul li a {
    display: inline-block;
    padding: 4px 0px;
    font-size: 1.5rem;
    color: #323232;
    font-weight: $wt-bold;
    text-transform: capitalize;
}

.sidebar-widget ul li a:hover {
    color: #3db54a;
}

.widget-tag {
    display: inline-table;
    margin-top: 15px;
}

.widget-tag li {
    float: left;
    margin-bottom: 5px;
    margin-right: 5px;
    /* text-transform: uppercase; */
}

.widget-tag li a {
    padding: 8px 10px 3px 10px!important;
    display: block;
    border: 1px solid $gray-lighter;
}

.widget-tag li a:hover {
    background: $gv-blue;
    color: #fff !important;
}

.sidebar-widget .widget-post {
    position: relative;
}

.sidebar-widget .widget-post .widget-post-media {
    width: 70px;
    display: table-cell;
    padding: 0px 15px 0px 0px;
}

.sidebar-widget .widget-post li {
    margin-bottom: 20px;
}

.sidebar-widget .widget-post a {
    padding: 0px;
}

.sidebar-widget .widget-post .widget-post-media img,
.sidebar-widget .widget-post .widget-post-media video {
    width: 100%;
    vertical-align: top;
}

.sidebar-widget .widget-post .widget-post-info {
    display: table-cell;
    vertical-align: top;
}

.sidebar-widget .widget-post .widget-post-info h5,
.sidebar-widget .widget-post .widget-post-info h6 {
    margin-bottom: 5px;
}

.sidebar-widget .widget-post .widget-post-info p {
    margin: 0px;
}

.share-widget {
    ul {
        text-align: center;
        .social-share {
            display: inline-block;
            width: 23%;
            a {
                font-size: 2rem;
                color: $gray-light;
            }
        }
    }
}


/* ----------------------------------------------------------------------*/


/* Pagination Navigation   --------------------------------------------- */


/* ----------------------------------------------------------------------*/

.pagination-nav {
    width: 100%;
    display: block;
    position: relative;
    text-align: center;
}

.pagination-nav ul {
    margin: 0px;
    padding: 0px;
}

.pagination-nav ul li {
    display: inline-block;
    margin: 0px 2px;
}

.pagination-nav ul li a {
    display: block;
    background-color: #f7f7f7;
    height: 36px;
    width: 36px;
    line-height: 36px;
    text-align: center;
    font-size: 14px;
}

.pagination-nav ul li.active a {
    background: $gv-blue;
    color: #fff;
}

.BlogPost {
    .article-cat {
        text-transform: uppercase;
    }
}

.warning .btn {
    font-size: 1.3rem;
    padding: 7px 10px 4px;
}