.sectionActionBox {
    &.c1-bg {
        background-color: $gray-lighter;
    }
    &.c2-bg {
        background-color: $gv-blue;
        .btn {
            background-color: $gv-green;
            color: white;
            &:hover,
            &:active,
            &:focus {
                background-color: lighten($gv-green, 9%);
            }
        }
    }
    &.c3-bg {
        background-color: $gv-green;
        .btn {
            background-color: $gv-blue;
            color: white;
            &:hover,
            &:active,
            &:focus {
                background-color: lighten($gv-blue, 9%);
            }
        }
    }
    &.c4-bg {
        background-color: black;
    }
    .title {
        font-weight: $wt-thin;
        margin-bottom: 0;
        top: 0.15em;
        position: relative;
            // font-size: 2.6em;
            // line-height: 1.4em;
            // @include media('screen', '>=tablet') {
            //     font-size: 1.3em;
            //     line-height: 1em;
            // }
          @include ms-respond(font-size, 2.4);

    }
    p {
        margin-top: 10px;
        margin-bottom: 0;
    }
    .row {
        font-size: 0;
    }
    &__left {
        @include col-tablet(2, 3, 15px, middle);
        text-align: center;
        @include media('screen', '>=tablet') {
            text-align: left;
        }
    }
    &__right {
        text-align: center;
        margin-top: 2rem;
        @include col-tablet(1, 3, 15px, middle); 
        @include media('screen', '>=tablet') {
            margin-top: 0;
        }
    }
    &__centered {
        text-align: center;
    }
}