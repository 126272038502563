.SectionCTAbox {
        p {
        font-size: ms(1.6);
    }
    &.c3-bg {
        .title {
            color: $gv-blue;
        }
        .callnow,
        .emailnow {
            color: $gv-blue;
        }
    }
    .title {
        color: $gv-green;
        @include ms-respond(font-size, 2.3);
    }
    .lead {
        font-size: ms(2);
        line-height: $minor-third;
    }
    .email-cta,
    .phone-cta {

        text-align: center;
    }
    .callnow,
    .emailnow {
        color: $gv-green;
        font-size: ms(2.3);
        margin-top: 4rem;
        @include media('screen', '>=tablet') {
            margin-top: 0;
        }
    }
    &__left {
        @include col-desk-lg(6, 12, 15px, middle);
        text-align: center;
        @include media('screen', '>=desktop-lg') {
            text-align: left;
            padding-right: 12%;
        }
    }
    &__right {
        text-align: center;
        margin-top: 4rem;
        @include col-desk-lg(6, 12, 15px, middle);
        @include media('screen', '>=desktop-lg') {
            margin-top: 0;
        }
    }
    .row {
        font-size: 0;
    }
}