@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

}

@mixin inline() {
display: inline-block;
float: none;
vertical-align: middle;
}

@mixin col-tablet($col, $sum, $gap: 1em, $align: top) {
 
  font-size: 1rem;

  @include media('screen', '>=tablet') {
     position: relative;
  width: percentage($col/$sum);
  display: inline-block;
  vertical-align: $align;
  box-sizing: border-box;
  padding: 0 $gap;
  float: none;
  }
}
@mixin col-desk($col, $sum, $gap: 1em, $align: top) {
 
  font-size: 1rem;

  @include media('screen', '>=desktop') {
     position: relative;
  width: percentage($col/$sum);
  display: inline-block;
  vertical-align: $align;
  box-sizing: border-box;
  padding: 0 $gap;
  float: none;
  }
}
@mixin col-desk-lg($col, $sum, $gap: 1em, $align: top) {
 
  font-size: 1rem;

  @include media('screen', '>=desktop-lg') {
     position: relative;
  width: percentage($col/$sum);
  display: inline-block;
  vertical-align: $align;
  box-sizing: border-box;
  padding: 0 $gap;
  float: none;
  }
}