.SectionIndustryFormContainer {
    .title {
        @include ms-respond(font-size, 3);

        &.ContactFormContainer {
            @include ms-respond(font-size, 4);
        }
    }

    .input {
        display: block;
    }

    .input span {
        position: absolute;
        z-index: 1;
        cursor: text;
        pointer-events: none;
        color: #999;
        /* Input padding + input border */
        padding: 14px 6px;
        /* Firefox does not respond well to different line heights. Use padding instead. */
        line-height: 17px;
        /* This gives a little gap between the cursor and the label */
        margin-left: 2px;
        font-family: $stack-prim;
        font-weight: $wt-thin;
        font-size: 1.4rem;
    }

    .input input,
    .input textarea,
    .input select,
    input[type="text"],
    input[type="email"],
    input[type="tel"],
    input[type="number"],
    input[type="password"],
    input[type="radio"],
    textarea,
    select {
        z-index: 0;
        padding: 12px;
        margin: 0;
        // margin-bottom: 10px;
        line-height: 17px;
        width: 100%;
        box-shadow: -4px 4px 12px 2px rgba(0, 0, 0, 0.05), 4px -4px 8px 2px rgba(0, 0, 0, 0.04), -4px -4px 8px 2px rgba(0, 0, 0, 0.04), 4px 4px 12px 2px rgba(0, 0, 0, 0.05);
        border-color: transparent;

        &:focus {
            border-color: $gv-green;
        }
    }

    .input select {
        padding: 5px;
        /* Unfortunately selects don't respond well to padding. They need an explicit height. */
        height: 31px;
    }

    .btn.c2-bg {
        background-color: $gv-green;
        color: white;
        font-size: 1.8rem;
        line-height: normal;
        font-weight: 700;
        padding: .5em 3em .2em 3em;
        margin-top: 48px;

        &:hover,
        &:active,
        &:focus {
            color: white;
            background-color: lighten($gv-green, 9%);
        }
    }

    #contact {
        input[type="checkbox"] {
            @include media('screen', '>tablet') {
                border: 1px solid black;
                padding: 4px;
                font-size: 18px;
                width: 22px;
                height: 22px;
                border-radius: 0;
                vertical-align: middle;
                margin-right: 12px;
                background-color: white;
                box-shadow: none;
                margin-top: 0;
            }
        }

        .phone {
            // margin-bottom: 40px;
        }

        label {
            font-weight: $wt-thin;
            font-size: 1.4rem;

            @include media('screen', '>tablet') {
                font-size: 1.6rem;
            }
        }
    }

    &__form-wrapper {
        margin-top: 24px;
    }


    .gdpr-wrapper {
        text-align: left;
        font-size: 1.6rem;
        color: #323232;
        font-weight: 300;

        div {
            margin-bottom: 10px;
            margin-top: 6px;
        }
    }
}


#__lpform_FirstName{display:none;}

#submitMessage1 {
    margin-top: 12px;
}