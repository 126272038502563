section.sectionActionBoxFull {
    text-align: center;
         @include media('screen', '>=desktop') {
            height: 300px;
         }
  .container {
            display: table;
            vertical-align: middle;
            height: 100%;
            
      
        }
        .inner-wrapper {
            display: table-cell;
            vertical-align: middle;
            height: 100%;
            padding: 0;
        }
        &.taller {
        @include media('screen', '>=desktop') {
            height: 500px;
            .container {
                padding: 40px 0;
            }
        }
    }
     &.shorter {
        @include media('screen', '>=desktop') {
            height: auto;
         
        }
    }
    .headline-wrapper {
        margin-top: 3rem;
        @include media('screen', '>=desktop') {
            margin-top: 0;
        }
    }
    .title {
        font-weight: $wt-bold;
        @include ms-respond(font-size, 4.4);
    }
    &.c2-bg {
        .btn.btn-white-line {
            background-color: transparent!important;
            color: white;
            // border-width: 4px;
            // text-transform: uppercase;
            // font-size: 3rem;
            // padding: 16px 40px 8px 40px;
            &:hover,
            &:active,
            &:focus {
                background-color: transparentize(white, 0.9)!important;
                color: white;
            }
            &:hover {
                background-color: transparentize(white, 0.9)!important;
            }
        }
    }
    .button-wrapper {
        margin-top: 48px;
    }
}