section.sectionAttachmentFeature {
    height: auto;
    @include media('screen', '>=desktop') {
        height: 800px;
        padding-top: 0;
        padding-bottom: 60px;
    }
    .container {
        //     display: table;
        height: 100%;
        // margin-top: -30px;
    }
    .inner-wrapper {
        text-align: left;
        height: 100%;
        //     width: 80%;
        //             display: table-cell;
        // vertical-align: middle;
        .wrapper {
            text-align: left;
        }
    }
    .button-wrapper {
        margin-top: 24px;
        margin-bottom: 24px;
    }
    .features-box-left {
        padding: 0;
        margin-bottom: 48px;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    .features-icon-left {
        text-align: center;
        padding-right: 15px;
        height: auto;
        // font-size: 63px;
        img,
        .ion {
            @include vertical-align();
            width: 65px;
        }
    }
    p,
    p.lead {
        margin-top: 0;
    }
    .feature-row {
        //  padding-left: 0;
    }
    .title {
        font-weight: $wt-bolder;
        font-size: ms(2.7);
    }
    .feature-row {
        margin-top: 24px;
    }

}