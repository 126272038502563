/*-------------------------------------------------------------------------------------------*/ /* Client Logo       ||---------------------------- */ /*-------------------------------------------------------------------------------------------*/

.client-logo {
    a.link {
        &:hover {
            img.iconimg {
                display: none;
                visibility: hidden;
            }
            img.iconimg-hover {
                display: block;
                visibility: visible;
            }
        }
    }
    // height: 110px;
    text-align: center;
    img.iconimg, img.iconimg-hover {
        height: 65px;
        width: auto;
        margin: 0 auto;
    }
    .title {
        margin-top: 24px;
        @include ms-respond(font-size, 2);

    }
  
    .inner-wrapper {
        width: auto;
        display: inline-block;
    }
    .icon-wrapper {
        height: auto;
    }
}

