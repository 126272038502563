.sectionPartnerList__categories {
    a {
        &:hover, &.active {
            .title {
                text-decoration: underline;
                text-decoration-color: $gv-green;
                text-decoration-style: solid;
            }
            img.iconimg {
                display: none;
                visibility: hidden;
            }
            img.iconimg-hover {
                display: block;
                visibility: visible;
            }
        }
    }
    &-item {
        margin-bottom: 46px;
        &:last-of-type {
            margin-bottom: 0;
        }
        @include media('screen', '>=desktop') {
            margin-bottom: 0;
        }
        text-align: center;
        img {
            margin: 0 auto;
        }
        .title {
            @include ms-respond(font-size, 3);
            @include media('screen', '>=desktop') {
                margin-bottom: 24px;
            }
        }
    }
    .page-icon-above, .page-icon-below, .icon-wrapper {
        height: 90px;
    }
    img.iconimg {
        width: 80px;
        display: block;
        visibility: visible;
    }
    img.iconimg-hover {
         width: 80px;
        display: none;
        visibility: hidden;
    }
}

.sectionPartnerList__partners {
    background: $gray-lighter;
    &-item {
          margin-bottom: 24px;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    &-item-logo {
        text-align: center;
        margin: 0 auto 24px auto;
        img {
                    max-height: 80px;
                    margin-left: auto;
                    margin-right: auto;

        }
    }
    .inner-wrapper {
            padding: 40px;
        position: relative;
        &.i-c1-bg {
            background-color: white;
            p {
                color: $gv-midgray;
            }
            .link:hover {
                color: $gv-green;
            }
        }
        &.b-c2-bg {
            border: 2px solid #dddddd;
        }
    }
    .innerlink {
        display: block;
        margin-top: 24px;
    }
    hr {
        border-top: 1px solid lighten($gv-midgray, 30%);
        margin-bottom: 0;
        margin-top: 33px;
    }
}