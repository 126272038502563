footer.footer {
    padding: 34px 0;
    background-color: $gray-lighter;
   // margin-top: 60px;
    .footer-info {
        margin-bottom: 0;
        [class^="col-"] {
            &:first-of-type {
                text-align: center;
                @include media('screen', '>=mid-tablet') {
                    text-align: left;
                }
                li {
                    a {
                        text-transform: uppercase;
                        font-weight: $wt-bolder;
                        letter-spacing: -.02em;
                    }
                }
            }
        }
    }
    .link {
        display: block;
        margin: 0 auto;
        @include media('screen', '<tablet') {
            text-align: center;
        }
        li {
            @include media('screen', '>mobile', '<tablet') {
                width: auto;
                float: none;
                display: inline-block;
            }
            &:first-child {
                margin-top: 0;
            }
            @include media('screen', '>=mid-tablet') {
                margin-top: 20px;
            }
        }
        a {
            color: black;
            font-size: 1.6rem;
            letter-spacing: -.02em;
            font-weight: $wt-med;
            line-height: 2.6rem;
            @include media('screen', '>=tablet') {
                font-size: 2rem;
            }
            &:hover,
            &:active,
            &:focus {
                color: $gv-green;
                text-decoration: none;
            }
        }
        li.leveltwo {
            margin-top: 0;
            &:first-child {
                // margin-top: 2px;
            }
            a {
    font-weight: 300;
    font-size: 1.8rem;
    text-transform: capitalize;
    letter-spacing: -.03em;
    line-height: 1.2;
            }
        }
    }
    .social {
        text-align: center;
        @include media('screen', '>=mid-tablet') {
            text-align: left;
        }
    }
    .connect {
        color: $gv-green;
        //margin-top: 42px;
        font-size: 2.6rem;
        letter-spacing: -.02em;
        font-weight: $wt-med;
    }

    .copyright {margin-top:30px;}
}