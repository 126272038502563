﻿@import "bootstrap-overides/customVariables";
@import "bootstrap-overides/customMixins";

.landing-page-content-container{
    max-width:1200px;
    margin:0 auto;
    .logo{ a{width:600px;display:block;
             img{opacity:1;display:block;width:100%;}
           }}
}

.green-bar{
    height:15px;
    width:100%;
    background-color:$gv-green;
    margin-top:20px;
    margin-bottom:40px;
}

.move-content-right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.contact-info{
    font-size:30px;
    display:block;
    width:450px;
    color:$gv-blue;
    margin-bottom:25px;
    .contact-lead{
        font-weight:700;
    }
    .contact-email{
        font-weight:300;
    }
}

body {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}
.page-content {
        flex: 1;
}


.landing-page-image {
    text-align:center;
    position: relative;
    img {
        width: 100%;
    }
    .image-headline { 
        position:absolute;
        top:0;
        bottom:0;
        right:0;
        left:0;
        width:100%;
        height:100%;
        display:flex;
        justify-content:center;
        align-items:center;

    }
    
}

.landing-page-endsection {
    padding-top: 40px;
    padding-bottom: 40px;
    margin: 20px;

    .left-text {
        font-size: 2.4rem;
        list-style: none;
        margin-left: 30px;
        position:relative;
        li {
            list-style: none;
            margin-left: 5px;
            margin-bottom:10px;
            margin-top:5px;
        }

        li:before {
            background: $gv-green;
            border-radius: 20px;
            color: #fff;
            content: "\2714";
            height: 20px;
            left: 15px;
            position: absolute;
            text-align: center;
            width: 20px;
            padding:5px;
            font-size:15px;
        }

        strong {
            color: $gv-blue;
            font-weight:900;
        }
    }

    @media(min-width:990px) {
        display: flex;
        flex-direction: row;

        .left-text {
            width: 60%;
        }

        .right-form {
            width: 35%;
        }
    }

    @media(min-width:1400px) {
        margin: 0 auto;
    }
}

.overlay-black-25 { background-color: rgba(0, 0, 0, 0.25);}
.overlay-black-50 { background-color: rgba(0, 0, 0, 0.5);}
.overlay-black-75 { background-color: rgba(0, 0, 0, 0.75);}
.overlay-white-25 { background-color: rgba(white, 0.25);}
.overlay-white-50 { background-color: rgba(white, 0.5);}
.overlay-white-75 { background-color: rgba(white, 0.75);}

.white-text {
    color: #fff;
}

.copyright{
    padding:20px;
    text-align:center;
}

.grey-contact-form-container {
    background-color: #E5E5E5;
    border-radius: 20px;
    padding: 40px;
    margin:20px;
    .form-intro {
        text-transform: uppercase;
        color: $gv-blue;
        margin: 0 auto 20px auto;
        font-size: 3.6rem;
    }

    input {
        width: 100%;
        background: none;
        border: 2px solid #989898;
    }
    input[type=checkbox]{
        width:auto;
    }

    .btn {
        background-color: $gv-blue;
        margin: 20px auto 0 auto;
        color: #fff;
        display: flex;
        text-transform: uppercase;
        font-weight: 300;
        align-items: center;
        flex-direction: column;
    }

    @media(min-width:1440px) {
        margin:0;
        margin-left: 50px;
    }
}