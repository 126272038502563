.SectionAddressAndPhone {
    &.c3-bg {
        .title {
            color: $gv-blue;
        }
    }
    &.dark-bg {
        a {
            color: white;
            &:hover, &:active, &:focus {
                color: transparentize(white, 0.15);
            }
        }
    }
    .title {
        color: $gv-green;
        @include ms-respond(font-size, 2.3);
    }
    &__left, &__mid, &__right {
        text-align: center;
        margin-bottom: 24px;
        &:last-of-type {
            margin-bottom: 0;
        }
        @include media('screen', '>=tablet') {
            text-align: left;
           margin-bottom: 0;

        }
    }
}