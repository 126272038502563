section.inner-intro {
    border-color: transparent transparent white transparent;
    text-align: center;
    &.c1-bg {
        background-color: $gray-lighter;
    }
    &.c2-bg {
        background-color: $gv-blue;
        .btn {
            background-color: $gv-green;
            color: white;
            &:hover,
            &:active,
            &:focus {
                background-color: lighten($gv-green, 9%);
            }
        }
    }
    &.c3-bg {
        background-color: $gv-green;
        .btn {
            background-color: $gv-blue;
            color: white;
            &:hover,
            &:active,
            &:focus {
                background-color: lighten($gv-blue, 9%);
            }
        }
    }
    &.c4-bg {
        background-color: black;
    }
    &.c5-bg {
        background-color: white;
    }
    p {
        //font-size: 2.4em;
        font-weight: 300;
        &.lead {
            @include ms-respond(font-size, 2.1);
        }
    }
    h1 {
        font-weight: $wt-bolder;
        margin-bottom: 0;
        margin-top: 12px;
       // text-transform: uppercase;
    }
}

