.sectionInfographic {
    .placeholder {
        border-right: 4px solid $gray-lighter;
        height: 100%;
        margin-top: 10px;
        position: relative;
        left: 52px;
        @include media('screen', '>=mid-tablet') {
            left: 56px;
        }
    }
    p,
    .title {
        color: $gv-midgray;
    }
    .title {
        font-weight: $wt-bold;
        @include ms-respond(font-size, 2.2);
    }
    .features-icon-left {
        text-align: center;
        /* position: relative; */
        /* top: -50%; */
        @include media('screen', '>mobile') {
            transform: translateY(-25%);
        }
        @include media('screen', '>=tablet') {
            transform: translateY(-38%);
        }
    }
    &__item {
        margin-bottom: 72px;
        &:last-of-type {
            margin-bottom: 0;
        }
        &:first-of-type {
            margin-top: 28px;
        }
 
        .icon-wrapper {
            height: auto;
            margin-bottom: 0;
                    @include media('screen', '>mobile') {
     height: 82px;
            margin-bottom: 24px;
                    }
            img,
            .icon {

                @include media('screen', '>=tablet') {
                    float: left;
                }
            }
           
        }
    }
}