﻿.navigation,
.navigation ul,
.navigation ul li,
.navigation ul li a,
.navigation #menu-button {
    margin: 0;
    padding: 0;
    border: 0;
    list-style: none;
    padding-top: 4px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding-top: 0;
    .inner-wrapper {
        @include media('screen', '>mid-tablet')
        {
        position: relative;

        }
    }
}

.navigation:after,
.navigation>ul:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}

.navigation #menu-button {
    display: none;
}

.navigation {
    position: static;
    float: right;
    font-family: $stack-prim;
    font-size: 12px;
    font-weight: 600;
    /* text-transform: uppercase; */
    margin-left: 15px;
    padding-top: 0;


    
}

.navigation ul li .nav-dropdown {
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
    &.open {
        padding: 14px 0;
        text-align: left;
        border-top: 2px solid $gv-green;
        height: 60vh;
        overflow-y: scroll;
        .inner-wrapper {
            width: 60%;
            margin: 0 15%;
            margin-top: 14px;
        }
    }
}

.navigation>ul>li {
    float: none;
   // position: relative;
    height: 105px;
    vertical-align: middle;
    display: inline-block;
}

.navigation.align-center>ul {
    font-size: 0;
    text-align: center;
}

.navigation.align-center>ul>li {
    display: inline-block;
    float: none;
}

.navigation.align-center ul ul {
    text-align: left;
}

.navigation.align-right>ul>li {
    float: right;
}

.navigation.align-right ul ul {
    text-align: right;
}

.navigation>ul>li>a {
    padding: 41px 16px;
    font-size: 12px;
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    // letter-spacing: 2px;
    text-decoration: none;
    /* text-transform: uppercase; */
    color: #323232;
    transition: all 0.25s ease 0s;
    -webkit-transition: all 0.25s ease 0s;
    -moz-transition: all 0.25s ease 0s;
    -o-transition: all 0.25s ease 0s;
    outline: none;
}

.header.sticky .navigation ul li a {
    display: inline-block;
    @include media('screen', '>mid-tablet') {
    padding: 0 0 0 22px;
    display: inline-block;
    float: none;
    vertical-align: middle;
       position: relative;
    top: 64%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    }


}

.header.sticky .navigation ul ul li a,
.header .navigation ul ul li a,
.navigation ul ul li a {
    padding: 10px 20px;
    color: #ddd;
    letter-spacing: 1px;
    font-weight: 500;
    font-size: 12px;
    outline: none;
    line-height: .75em;
    
}

.navigation ul li:hover a,
.header.sticky .navigation ul>li:hover a,
.navigation>ul>li.active a,
.navigation>ul>li>ul>li.active a,
.navigation>ul>li>ul>li>ul>li.active a,
.header.sticky .navigation ul ul>li.active>a,
.header.sticky .navigation ul>li.active a,
.header.sticky .navigation>ul>li>ul>li.active a,
.header.sticky .navigation>ul>li>ul>li>ul>li.active a {
    @include media('screen', '>mid-tablet') {
        color: $gv-green;
    }
    // text-shadow: 0 0 0.65px #3db54a, 0 0 0.65px #3db54a;
}

.header.sticky .navigation>ul>li:hover>a {
    //   text-shadow: 0 0 0.65px #3db54a, 0 0 0.65px #3db54a;
    // font-weight: $wt-bold;
    //    -webkit-text-fill-color: #3db54a; /* Will override color (regardless of order) */
    //    -webkit-text-stroke-width: 1px;
    //    -webkit-text-stroke-color: #3db54a;
}

header.sticky .navigation>ul>ul li.leveltwo::hover>a {
    //   text-shadow: 0 0 0.15px #3db54a, 0 0 0.15px #3db54a;
}


/*.navigation > ul > li.nav-has-sub > a {  padding-right: 25px;}*/


/*.navigation > ul > li.nav-has-sub > a::after {  position: absolute;  top: 21px;  right: 10px;  width: 4px;  height: 4px;  border-bottom: 1px solid #323232;  border-right: 1px solid #323232;  content: "";  -webkit-transform: rotate(45deg);  -moz-transform: rotate(45deg);  -ms-transform: rotate(45deg);  -o-transform: rotate(45deg);  transform: rotate(45deg);  -webkit-transition: border-color 0.2s ease;  -moz-transition: border-color 0.2s ease;  -ms-transition: border-color 0.2s ease;  -o-transition: border-color 0.2s ease;  transition: border-color 0.2s ease;}*/

.navigation>ul>li.nav-has-sub:hover a::after {
    border-color: $gv-green;
}

.navigation ul ul {
    position: absolute;
    right: -9999px;
    opacity: 0;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
    top: 130%;
    visibility: hidden;
}

.navigation li:hover ul {
    opacity: 1;
    top: 105px;
    visibility: visible;
    border-top: 2px solid $gv-green;
    @include media('screen', '>mid-tablet') {
        left: 15px;
        width: 100%;
        
    }
    .inner-wrapper {
            padding-top: 15px;
    }
}

.navigation li:hover ul.nav-sub-dropdown {
    top: 0;
}

.navigation.align-right li:hover ul {
    right: 0;
}

.navigation ul ul ul {
    margin-left: 100%;
    top: 0;
}

.navigation .nav-dropdown,
.navigation .nav-sub-dropdown {
    padding-top: 15px;
    padding-bottom: 15px;
    background: #ddd;
}

.navigation li:hover ul.nav-sub-dropdown {
    top: -10px;
}

.navigation.align-right ul ul ul {
    margin-left: 0;
    margin-right: 100%;
}


/*.navigation ul ul li {  height: 0;  -webkit-transition: height .2s ease;  -moz-transition: height .2s ease;  -ms-transition: height .2s ease;  -o-transition: height .2s ease;  transition: height .2s ease;}*/


/*.navigation ul li:hover > ul > li {  height: 32px;}*/

.header.sticky .navigation ul .nav-dropdown li a {
        padding: 0;
    width: auto;
    font-size: 1.4rem;
    line-height: 1;
    text-decoration: none;
    color: black;
    -webkit-transition: color .2s ease;
    -moz-transition: color .2s ease;
    -ms-transition: color .2s ease;
    -o-transition: color .2s ease;
    transition: color .2s ease;
     //   padding: 5px 0;
    &:hover {
        color: $gv-green;
    }
}
.header.sticky .navigation ul .nav-dropdown li span {
}
.navigation ul ul li:hover>a,
.navigation ul ul li a:hover {
    color: $gv-green;
}

.navigation ul ul li.nav-has-sub a::after {
    // position: absolute;
    // top: 13px;
    // right: 15px;
    // width: 5px;
    // height: 5px;
    // border-bottom: 1px solid #fff;
    // border-right: 1px solid #fff;
    // content: "";
    // -webkit-transform: rotate(-45deg);
    // -moz-transform: rotate(-45deg);
    // -ms-transform: rotate(-45deg);
    // -o-transform: rotate(-45deg);
    // transform: rotate(-45deg);
    // -webkit-transition: border-color 0.2s ease;
    // -moz-transition: border-color 0.2s ease;
    // -ms-transition: border-color 0.2s ease;
    // -o-transition: border-color 0.2s ease;
    // transition: border-color 0.2s ease;
}

.navigation.align-right ul ul li.nav-has-sub a::after {
    right: auto;
    left: 10px;
    border-bottom: 0;
    border-right: 0;
    border-top: 1px solid $gv-green;
    border-left: 1px solid $gv-green;
}

.navigation ul ul li.nav-has-sub:hover a::after {
    border-color: $gv-green;
}

@include media('screen', '<991px') {
    .navigation {
        width: auto;
        margin-left: 0px;
    }
    .navigation ul {
        display: none;
        left: 0;
        width: 100%;
        background: #fff;
        overflow: auto;
        position: fixed;
        top: 105px;
        z-index: 2;
        height: auto;
        padding-top: 0 !important;
        background: $gv-blue;
        overflow: hidden;
        border-bottom: 1px solid $gv-green;
    }
    .navigation>ul>li {
        a {
            color: white;
            &.open {
                //  background: white;
                color: $gv-green;
            }
        }
    }
    .navigation .nav-dropdown,
    .navigation .nav-sub-dropdown {
        padding-top: 0px;
        padding-bottom: 0px;
        background: $gray-lighter;
    }
    .navigation li:hover ul,
    .navigation li:hover ul.nav-sub-dropdown {
        top: auto;
    }
    .navigation ul ul {
        opacity: 1;
        visibility: visible;
    }
    .navigation>ul,
    .header.sticky .navigation>ul {
        >li {
            a {
                padding: 0 4px 0 16px;
              line-height: 3;
                color: white;
                padding-top: 4px;
                &.open {
                    color: $gv-green;
                    font-weight: 600;
                    padding: 0;
                    padding-left: 15%;
                }
            }
        }
    }
    .navigation.align-center>ul,
    .navigation.align-right ul ul {
        text-align: left;
    }
    .navigation ul li,
    .navigation ul ul li,
    .navigation ul li:hover>ul>li {
      //  width: auto;
        height: auto;
        border-top: 1px solid $gv-green;
        display: block;
    }
    .navigation ul li a,
    .navigation ul ul li a {
        // width: 100%;
    }
    .navigation>ul>li,
    .navigation.align-center>ul>li,
    .navigation.align-right>ul>li {
        float: none;
        display: block;
        //  max-width: 750px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        &.open {
            text-align: left;
            span.inside-wrapper a {
                width: auto;
                margin: 0 auto;
                text-align: left;
            }
            background: white;
            a {
                color: $gv-green;
            }
        }
    }
    .navigation ul ul li a {
        padding: 20px 20px 20px 30px;
        font-size: 12px;
        color: #323232;
        background: none;
    }
    .header.sticky .navigation ul ul li a,
    .header .navigation ul ul li a {
        padding: 20px 20px 20px 30px;
        color: #323232;
    }
    .header.sticky .navigation ul ul ul li a,
    .header .navigation ul ul ul li a {
        padding: 20px 20px 20px 45px;
    }
    .navigation ul ul li:hover>a,
    .navigation ul ul li a:hover {
        color: $gv-green;
    }
    .navigation ul ul ul li a {
        padding-left: 40px;
    }
    .navigation ul ul,
    .navigation ul ul ul {
        position: relative;
        left: 0;
        right: auto;
        width: 100%;
        margin: 0;
    }
    .navigation>ul>li.nav-has-sub a::after,
    .navigation ul ul li.nav-has-sub a::after {
        display: none;
    }
    #menu-line {
        display: none;
    }
    .navigation #menu-button {
        display: block;
        padding: 0;
        margin-left: 15px;
        cursor: pointer;
        font-size: 12px;
        /* text-transform: uppercase; */
        z-index: 2;
        float: left;
        margin-top: 0;
        a {
            color: $gray-light2;
        }
    }
    /*.navigation #menu-button::after {            content: '';            position: absolute;            top: 20px;            right: 20px;            display: block;            width: 15px;            height: 2px;            background: #000000;        }        .navigation #menu-button::before {            content: '';            position: absolute;            top: 25px;            right: 20px;            display: block;            width: 15px;            height: 3px;            border-top: 2px solid #000000;            border-bottom: 2px solid #000000;        }*/
    .navigation .submenu-button {
        position: relative;
        z-index: 10;
        /* right: 0; */
            bottom: 2px;

        display: inline;
        /* border-left: 1px solid #eee; */
        height: 37px;
        width: 52px;
        cursor: pointer;
        color: white;
        &.submenu-opened {
            color: $gv-green;
            top: -2px;
        }
    }
    .navigation .submenu-button::after {
        font-family: FontAwesome;
        content: "\f054";
        margin-left: 7px;
        font-size: 1.2rem;
        position: relative;
        //   bottom: 2px;
        color: white;
    }
    .navigation .submenu-button::before {
        // content: '';
        // position: absolute;
        // left: 21px;
        // top: 20px;
        // display: block;
        // width: 11px;
        // height: 1px;
        // background: white;
        // z-index: 99;
    }
    .navigation .submenu-button.submenu-opened:after {
        //   display: none;
        color: $gv-green;
    }
}
