﻿.deluxe-topper {
    &.mobile {
        display: block;
    }

    &.desktop {
        display: none;
    }

    width: 100%;

    @media(min-width:768px) {
        margin-top: 50px;

        &.mobile {
            display: none;
        }

        &.desktop {
            display: block;
        }
    }
}
