/*Button Style   ||-----------*/

.btn:focus {
    outline: none;
}

.btn,
button, input.btn {
    font-size: 1.8rem;
    line-height: normal;
    font-weight: 700;
    /*letter-spacing: 2px;*/
    /* text-transform: uppercase; */
    transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    -o-border-radius: 2px;
}

.btn i,
button i,
i.left {
    margin-right: 6px;
}

i.right {
    margin-left: 6px;
    margin-right: 0px !important;
}

button {
    vertical-align: top;
}

.btn-full {
    width: 100% !important;
    display: inline-block;
    text-align: center;
}

.btn-lg {
    padding: 15px 25px;
    display: inline-block;
}

.btn-md {
    // padding: 11px 42px;
        padding: .5em 2em .2em 2em;
    display: inline-block;
}

.btn-xs {
    padding: 11px 15px 10px;
    display: inline-block;
}

.btn-line-xs {
    padding: 7px 15px 7px;
    display: inline-block;
}

.btn-icon {
    padding: 0px;
    width: 34px;
    height: 34px;
    line-height: 34px;
    display: inline-table !important;
}

.btn-color-a,
.btn-color-b {
    color: #fff;
    background: #3db54a;
    border: none;
}

.btn-color-a:hover {
    color: #fff;
    background: rgba(230, 174, 73, 0.85);
}

.btn-color-b:hover {
    color: #fff;
    /*color: #3db54a;        background: #fff;*/
    background: rgba(230, 174, 73, 0.85);
}

.btn-black-line,
.btn-white-line,
.btn-color-line,
.btn-green-line,
.btn-blue-line,
.btn-gray-line {
    border: 2px solid;
    background: none;
}

.btn-green {
    color: #ffffff;
    background: $gv-green;
    border: none;
    &:hover {
        background-color: lighten($gv-green, 9%);
        color: #ffffff;
    }
    &-line {
        color: $gv-green;
        border-color: $gv-green;
        padding: 9px 20px;
        &:hover {
            color: #fff;
            background: $gv-green;
        }
    }
}
.btn-green {
    color: #ffffff;
    background: $gv-green;
    border: none;
    &:hover {
        background-color: lighten($gv-green, 9%);
        color: #ffffff;
    }
    &-line {
        color: $gv-green;
        border-color: $gv-green;
        padding: 9px 20px;
        &:hover {
            color: #fff;
            background: $gv-green;
        }
    }
}
.btn-gray {
    color: #000000;
    background: $gray-lighter;
    border: none;
    &:hover {
        background-color: lighten($gray-lighter, 15%);
        color: #000000;
    }
    &-line {
        color: $gray-lighter;
        border-color: $gray-lighter;
        padding: 9px 20px;
        &:hover {
            color: #000000;
            background: $gray-lighter;
        }
    }
}
.btn-blue {
    color: #ffffff;
    background: $gv-blue;
    border: none;
    &:hover {
        background-color: lighten($gv-blue, 9%);
        color: #ffffff;
    }
    &-line {
        color: $gv-blue;
        border-color: $gv-blue;
        padding: 9px 20px;
        &:hover {
            color: #fff;
            background: $gv-blue;
        }
    }
}

.btn-black {
    color: #fff;
    background: #323232;
    border: none;
    &:hover {
        color: #fff;
        /*background: #3db54a;*/
        background: rgba(50, 50, 50, 0.85);
    }
    &-line {
        color: #323232;
        border-color: #323232;
        padding: 9px 20px;
        &:hover {
            color: #fff;
            background: #323232;
        }
    }
}

.btn-color-line {
    color: #3db54a;
    border-color: #3db54a;
    padding: 9px 20px;
}

.btn-color-line:hover {
    color: #fff;
    background: #3db54a;
}

.btn.btn-white {
    color: #323232;
    background: rgba(255, 255, 255, 1);
    border: none;
    &:hover {
        /*color: #fff;        background: #3db54a;*/
        background: rgba(255, 255, 255, 0.85);
        color: #323232;
    }
    &-line {
        color: #fff;
        border-color: #fff;
        padding: 11px 20px 7px 20px;
        background-color: transparent;
        .SectionCTAbox & {
        background-color: transparent;
        padding-left: 50px;
        padding-right: 50px;
        font-weight: $wt-thin;
        text-transform: uppercase;
        font-size: ms(.8);
          @include media('screen', '>=desktop-lg') {
        padding: 11px 20px 7px 20px;

        //width: 100%;
          }

        }
        &:hover {
            color: #323232;
            background: #fff;
        }
    }
}

.btn-white .btn-link-a,
.btn-link-b {
    color: #323232;
    background: none;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 3px;
    border-bottom: 1px dotted transparent;
    font-weight: 600;
    font-size: 12px;
    /* text-transform: uppercase; */
    letter-spacing: 2px;
}

.btn-link-a:hover {
    color: #3db54a;
    border-bottom: 1px solid #3db54a;
}

.btn-link-b:hover {
    color: #fff;
    border-bottom: 2px solid #fff;
}

.btn-space-5 {
    width: 5px;
    min-height: 1px;
    display: inline-block;
}

.btn-space-10 {
    width: 10px;
    min-height: 1px;
    display: inline-block;
}