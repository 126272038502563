body.partner-portal {
    padding-top: 0;

    .portal-footer {
        background-color: #293273;
        padding-top: 60px;
        padding-bottom: 60px;

        .title {
            color: #3db54a;
            font-size:24px;
            font-weight:400;
        }
        .rep-title{
            color:#fff;
            p{ color:#fff;}
        }
        &.copyright{
            background-color:#efefef;
            padding-top:60px;
            padding-bottom:60px;
            text-align:center;
        }
    }


    h1, h2, h3, h4, h5, h6 {
        font-size: unset;
    }

    .header {
        .logo {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 0;
            position: relative;
            transform: none;
            width: 100%;
            height: 100px;
            max-width: 280px;

            &.right-align {
                float: right;
            }
        }
    }

    .header-logout {
        background-color: #efefef;
        margin-bottom: 22px;

        .login-section {
            display: flex;
            flex-direction: row;

            .column {
                height: 50px;
                align-items: center;
                justify-content: center;
                display: flex;
            }
        }


        .username {
            font-style: italic;
            margin: 0 20px 0 5px;
        }

        button {
            text-align: center;
            display: flex;
            height: 50px;
            align-items: center;
            justify-content: center;
            background: none;
            margin: 0 auto;

            .fa {
                margin-right: 0;
                margin-left: 10px;
                font-size: 20px;
                color: #3db54a;

                &:hover {
                    color: red;
                }
            }
        }
    }

    .downloads-section {
        padding-top: 60px;
        padding-bottom: 60px;
        background-color: #efefef;

        .pdf-section {
            display: flex;
            flex-direction: row;
            width: 100%;
            flex-wrap: wrap;
            align-items: stretch;

            .pdf-heading {
                text-align: center;
                font-size: 21px;
                border-bottom: 2px solid #808080;
                padding-bottom: 20px;
                padding-top: 20px;
            }

            .pdf-folder {
                display: flex;
                flex-direction: column;
                padding: 20px;
                flex-grow: 1;
                flex: 1 0 30%;
                max-width: 100%;

                .pdf-list {
                    padding: 20px 0;
                    margin-top: 10px;

                    .pdf-item {
                        display: flex;
                        flex-direction: row;
                    }

                    .pdf {
                        display: flex;
                        flex-direction: column;
                        flex-grow: 3;
                        justify-content: center;
                    }

                    .btn {
                        display: flex;
                        flex-direction: column;
                        flex-grow: 0;
                        margin: 10px 0;
                        font-size: 12px;
                    }
                }
            }
        }
    }

    .partner-intro {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .headline-wrapper {
        hr {
            margin: 0 auto 24px;
        }

        padding-bottom: 30px;

        .title {
            text-align: center;
            font-size: 31px;

            @media(min-width:1200px) {
                font-size: 40px;
            }
        }
    }

    .contact-section, .demo-section {
        padding-top: 60px;
        padding-bottom: 60px;
        border-top: 2px solid #efefef;
    }
}



.partner-login {
    padding: 60px 0;

    input {
        width: 100%;
        max-width: 400px;
        z-index: 0;
        padding: 12px;
        line-height: 17px;
        box-shadow: -4px 4px 12px 2px rgba(0,0,0,.05), 4px -4px 8px 2px rgba(0,0,0,.04), -4px -4px 8px 2px rgba(0,0,0,.04), 4px 4px 12px 2px rgba(0,0,0,.05);
        border-color: transparent;
    }

    .lead {
        padding: 0 0 30px 0;
        text-align: center;
        max-width: 560px;
        margin: 0 auto;
        line-height: 36px;
    }

    h1, h2, h3, h4, h5, h6 {
        font-size: unset;
    }

    .headline-wrapper {
        hr {
            margin: 0 auto 24px;
        }

        padding-bottom: 30px;

        .title {
            text-align: center;
            font-size: 31px;

            @media(min-width:1200px) {
                font-size: 40px;
            }
        }
    }
}