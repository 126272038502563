.SectionStatement {
         @include media('screen', '>=desktop') {
            height: 300px;
         }
      a {
        &:hover {
            img.iconimg {
                display: none;
                visibility: hidden;
            }
            img.iconimg-hover {
                display: block;
                visibility: visible;
            }
        }
    }
        .container {
            display: table;
            vertical-align: middle;
            height: 100%;
            
      
        }
        .inner-wrapper {
            display: table-cell;
            vertical-align: middle;
            height: 100%;
            padding: 0;
        }
    
    position: relative;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;

    // &.plax {
    //     background-attachment: fixed;
    //     .ie & {
    //         background-attachment: scroll;
    //     }
    // }
    &.taller {
        @include media('screen', '>=desktop') {
            height: 500px;
            .container {
                padding: 40px 0;
            }
        }
    }
     &.shorter {
        @include media('screen', '>=desktop') {
            height: auto;
         
        }
    }
    .title {
        font-weight: $wt-bold;
        @include ms-respond(font-size, 4.3);
        margin-bottom: 0;
    }
    p {
        font-size: ms(1.6);
        margin-bottom: 0;
                @include media('screen', '>=desktop') {

        font-size: ms(2);

                }
    }
    p.lead {
        margin-bottom: 0;
    }
    

}