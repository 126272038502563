@import "../../node_modules/modularscale-sass/stylesheets/modularscale";
@import "bootstrap-overides/customVariables";

// $font-google-prim: 'https://fonts.googleapis.com/css?family=Khula:300,400,600,700,800';
$font-google-prim: 'https://fonts.googleapis.com/css?family=Hind+Guntur:300,400,500,600,700';
@if variable-exists(font-google-prim) {
    @import url($font-google-prim);
}

$stack-prim: 'Hind Guntur', sans-serif;
$wt-thin: 300;//light
$wt-reg: 400;//reg
$wt-med: 500;//med
$wt-bold: 600;//semi
$wt-bolder: 700;//bold


$modularscale: (
  base: 10px,
  ratio: 68at5,
  lead: (
    ratio: 24at2,

  ),
  400px: (
      base: 10px,
    ratio: 48at5,

  ),
  900px: (
  base: 10px,
    ratio: 58at5,

  ),
    1024px: (
  base: 10px,
    ratio: 68at5,

  ),
  1200px: (
     base: 10px,
    ratio: 68at5,
  ),
);

body {

    color: #323232;
    font-size: ms(1.3);
    font-family: $stack-prim;
    font-weight: $wt-reg;
    // letter-spacing: 1px;
    overflow-x: hidden!important;
    line-height: $minor-third;
}
.lead {
    font-size: ms(2, $thread: lead);
    line-height: $minor-third;

}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #323232;
    /* text-transform: uppercase; */
    font-family: $stack-prim;
    font-weight: $wt-bold;
    letter-spacing: -0.025em;
    line-height: 1;
    margin: 0;
    margin-top: 0;
    margin-bottom: $vertical;
}


h1 {
  @include ms-respond(font-size, 5);
  line-height: 1;
}
h2 {
  @include ms-respond(font-size, 4);
  line-height: 1;
}
h3 {
  @include ms-respond(font-size, 3);
  line-height: 1;
}
h4 {
  @include ms-respond(font-size, 2);
  line-height: 1;
}
h5 {
  @include ms-respond(font-size, 1);
  line-height: 1;
}
h6 {
  @include ms-respond(font-size, .75);
  line-height: 1;
}
.btn,
button {
    font-family: $stack-prim;
    line-height: normal;
    font-weight: $wt-bold;
    font-family: $stack-prim;
   // letter-spacing: 2px;
    /* text-transform: uppercase; */
    transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    -o-border-radius: 2px;
}

button,
input,
textarea,
select {
    font-family: $stack-prim;
    font-size: 12px;
}

input,
textarea,
select {
    margin-bottom: 20px;
    font-family: $stack-prim;
    font-size: 12px;
    line-height: 14px;
}

label {
    color: #323232;
    font-size: 13px;
    margin-bottom: 10px;
    padding-right: 3px;
    margin-top: 6px;
    font-weight: $wt-bold;
    font-family: $stack-prim;
}

.cart .cart-notification {
    position: absolute;
    width: 16px;
    height: 16px;
    font-size: 11px;
    color: #fff;
    right: -5px;
    bottom: -3px;
    display: block;
    background: $gv-green;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    font-weight: $wt-bold;
    text-align: center;
    line-height: 14px;
    font-family: $stack-prim;
}

.container-filter li a {
    display: block;
    margin-left: 15px;
    margin-right: 15px;
    /* text-transform: uppercase; */
    letter-spacing: 2px;
    font-size: 12px;
    font-weight: $wt-bold;
    font-family: $stack-prim;
}

a:hover {
    color: $gv-green;
    text-decoration: none;
    transition: all 0.25s ease 0s;
    -moz-transition: all 0.25s ease 0s;
    -webkit-transition: all 0.25s ease 0s;
    -o-transition: all 0.25s ease 0s;
}

p {

    font-weight: $wt-thin;
    color: #222;
    // letter-spacing: 1px;
}

p.lead,
.lead {
    // line-height: 1.5;
    // font-size: 1.6rem;
    // font-weight: $wt-thin;
}
a:hover, a:focus {
    text-decoration: none;
}

